import React, { FC } from 'react';
import { HydrateProps } from 'react-query/hydration';
import { SharedShell } from '@homeproved/shared/shell';
import { PageScrollContextProvider } from '@homeproved/shared/util';

type Props = {
  deviceType?: string;
  dehydratedState?: HydrateProps['state'];
};

const ComShell: FC<Props> = ({ children, dehydratedState, deviceType }) => (
  <PageScrollContextProvider>
    <SharedShell dehydratedState={dehydratedState} deviceType={deviceType} ignoreJwt>
      {children}
    </SharedShell>
  </PageScrollContextProvider>
);

export default ComShell;
