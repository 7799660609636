import React, { useEffect } from 'react';
import Head from 'next/head';
import './styles.css';
import { ComShell } from '@homeproved/com/shell';
import { appWithTranslation } from '@homeproved/shared/feature-i18n';
import { useLocalizedRoutes } from '@homeproved/com/feature-localized-routes';
import { AppProps, PageAuthenticationWrapper } from '@homeproved/shared/feature-auth';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';

const CustomApp = ({ Component, pageProps, deviceType }: AppProps & { deviceType: string }) => {
  const { getPath } = useLocalizedRoutes();
  const { t } = useTranslation();

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID_COM,
      auth: process.env.NEXT_PUBLIC_GTM_AUTH_COM,
      preview: process.env.NEXT_PUBLIC_GTM_PREVIEW_COM,
    });
  }, []);

  const replaceVariables = function (templateString: string) {
    if (pageProps?.meta?.variables != null) {
      Object.keys(pageProps.meta.variables).forEach((key) => {
        templateString = templateString.replace(key, pageProps.meta.variables[key]);
      });
    }
    return templateString;
  };

  return (
    <>
      <Head>
        <title>
          {pageProps?.meta?.title ? replaceVariables(t(pageProps.meta.title)) : 'Homeproved.com'}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1" />
        {!!pageProps?.meta?.description && (
          <meta name="description" content={replaceVariables(t(pageProps.meta.description))} />
        )}
        <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#d9023a" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon/favicon.ico" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-config" content="/browserconfig.xml" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="og:image" property="og:image" content={pageProps?.ogImage} />
        <meta name="twitter:image" property="twitter:image" content={pageProps?.ogImage} />
        <meta name="og:type" property="og:type" content="website" />
        <meta
          name="og:title"
          property="og:title"
          content={
            pageProps?.meta?.title ? replaceVariables(t(pageProps.meta.title)) : 'Homeproved.com'
          }
        />
        <meta
          name="twitter:title"
          property="twitter:title"
          content={
            pageProps?.meta?.title ? replaceVariables(t(pageProps.meta.title)) : 'Homeproved.com'
          }
        />
        <meta property="og:site_name" content="Homeproved" />
        <meta name="twitter:site" property="twitter:site" content="Homeproved" />
        {!!pageProps?.meta?.description && (
          <>
            <meta
              name="og:description"
              property="og:description"
              content={replaceVariables(t(pageProps.meta.description))}
            />
            <meta
              name="twitter:description"
              property="twitter:description"
              content={replaceVariables(t(pageProps.meta.description))}
            />
          </>
        )}
        <meta name="og:url" property="og:url" content={pageProps?.url} />
        <meta name="twitter:card" property="twitter:card" content="summary" />
        <link rel="stylesheet" href="https://use.typekit.net/yql6lhl.css" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://p.typekit.net" />
        <link rel="preconnect" href="https://use.typekit.net" />
        <link rel="preconnect" href="https://cdnjs.cloudflare.com" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <script
          type="text/javascript"
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_API_KEY}&libraries=places&language=nl&region=BE`}
        />
      </Head>
      <ComShell deviceType={deviceType} dehydratedState={pageProps?.dehydratedState}>
        <div id="map" />
        <PageAuthenticationWrapper
          authenticate={Component.authenticate}
          redirectAuthenticatedTo={
            Component.redirectAuthenticatedTo
              ? getPath(Component.redirectAuthenticatedTo)
              : undefined
          }
          redirectUnAuthenticatedTo={Component.redirectUnAuthenticatedTo}
          defaultRedirectUnAuthenticatedTo={getPath('/')}
        >
          <Component {...pageProps} />
        </PageAuthenticationWrapper>
      </ComShell>
    </>
  );
};

export default appWithTranslation(CustomApp);
