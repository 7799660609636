import React, { FC } from 'react';
import { QueryClientProvider } from 'react-query';
import { Hydrate, HydrateProps } from 'react-query/hydration';
import { CookiesProvider } from 'react-cookie';
import {
  AppTheme,
  desktopMuiTheme,
  mobileMuiTheme,
  NotistackProvider,
} from '@homeproved/shared/ui';
import { PersistentDataProvider, queryClient } from '@homeproved/shared/data-access';
import { AutoLogin } from '@homeproved/shared/feature-auth';
import { AuthorizationRoot } from '@homeproved/shared-feature-auth-codana';
import { getURLWithoutSubdomain, usePageScroll, useRouterScroll } from '@homeproved/shared/util';
import { RegistrationFormDataProvider } from '@homeproved/shared/feature-forms';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { SlugContextProvider } from '@homeproved/shared/feature-i18n';

type Props = {
  dehydratedState?: HydrateProps['state'];
  ignoreJwt?: boolean;
  deviceType?: string;
};

export const SharedShell: FC<Props> = ({
  ignoreJwt = false,
  children,
  dehydratedState,
  deviceType,
}) => {
  const { pageScrollEnabled } = usePageScroll();
  useRouterScroll({ pageScrollEnabled });
  const cookieDomain =
    typeof window !== 'undefined' ? getURLWithoutSubdomain(window.location.hostname) : undefined;

  return (
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <PersistentDataProvider>
          <SlugContextProvider>
            <RegistrationFormDataProvider>
              <Hydrate state={dehydratedState}>
                <AppTheme theme={deviceType === 'mobile' ? mobileMuiTheme : desktopMuiTheme}>
                  <NotistackProvider>
                    <AuthorizationRoot
                      autoLogin={<AutoLogin />}
                      cookieDomain={cookieDomain}
                      ignoreJwt={ignoreJwt}
                    >
                      <GoogleReCaptchaProvider
                        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
                      >
                        {children}
                      </GoogleReCaptchaProvider>
                    </AuthorizationRoot>
                  </NotistackProvider>
                </AppTheme>
              </Hydrate>
            </RegistrationFormDataProvider>
          </SlugContextProvider>
        </PersistentDataProvider>
      </QueryClientProvider>
    </CookiesProvider>
  );
};
